import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { RouteNames } from '@/setup/constants.ts'
import { useUnauthenticatedGuard } from '@/shared/auth/useAuthenticationGuards.ts'

export const RoutesLogin: RouteRecordRaw[] = [
  {
    path: '/login',
    name: RouteNames.LOGIN,
    component: retriableLazyImport(() => import('./PageLogin.vue')),
    beforeEnter: useUnauthenticatedGuard(),
  },
]
