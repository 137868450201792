<template>
  <RouterView v-slot="{ Component }">
    <template v-if="Component">
      <Transition
        mode="out-in"
        name="fade"
      >
        <Suspense>
          <div>
            <Component
              :is="Component"
              :key="locale"
            />
          </div>

          <template #fallback>
            <LoadingAnimation />
          </template>
        </Suspense>
      </Transition>
    </template>
  </RouterView>

  <Toaster />
</template>

<script setup lang="ts">
import LoadingAnimation from '@gcx-si/vue-components/LoadingAnimation'
import Toaster from '@gcx-si/vue-components/Toaster'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
</script>
