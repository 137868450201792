import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { RouteNames } from '@/setup/constants.ts'

export const RoutesGatewayGroups: RouteRecordRaw[] = [
  {
    path: 'gateway-groups',
    meta: {
      navigation: {
        labelKey: 'navigation.gateway_groups.label',
        targetName: RouteNames.GATEWAY_GROUPS__OVERVIEW,
      },
    },
    children: [
      {
        path: '',
        name: RouteNames.GATEWAY_GROUPS__OVERVIEW,
        component: retriableLazyImport(() => import('./PageOverview.vue')),
      },
      {
        path: ':gatewayGroupId',
        name: RouteNames.GATEWAY_GROUPS__GROUP_DETAIL,
        component: retriableLazyImport(() => import('./PageGroupDetail.vue')),
      },
      {
        path: ':gatewayGroupId/gateways/:gatewayId',
        name: RouteNames.GATEWAY_GROUPS__GATEWAY_DETAIL,
        component: retriableLazyImport(() => import('./PageGatewayDetail.vue')),
      },
    ],
  },
]
