import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUpRightFromSquare,
  faArrowRightFromBracket,
  faBarcodeRead,
  faBatteryBolt,
  faChartNetwork,
  faClipboardListCheck, faCopy,
  faGauge,
  faGrid2Plus,
  faHammerCrash,
  faHome,
  faLink,
  faSensorOn,
  faTowerCell,
  faBook,
  faTrashCan,
  faCalendarCirclePlus
} from '@fortawesome/pro-regular-svg-icons'
import { icons as componentLibIcons } from '@gcx-si/vue-components/icons'

export const icons = [
  ...componentLibIcons,
  faHome,
  faGrid2Plus,
  faChartNetwork,
  faClipboardListCheck,
  faLink,
  faGauge,
  faBatteryBolt,
  faSensorOn,
  faTowerCell,
  faArrowUpRightFromSquare,
  faArrowRightFromBracket,
  faBarcodeRead,
  faHammerCrash,
  faBook,
  faCopy,
  faTrashCan,
  faCalendarCirclePlus,
]

export function setupIcons() {
  library.add(...icons)
}
