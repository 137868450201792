import type { NavigationGuard } from 'vue-router'

import { useAuthenticationStore } from '@/shared/auth/useAuthenticationStore.ts'
import { RouteNames, StorageKeys } from '@/setup/constants.ts'
import { useUserStore } from '@/stores/useUserStore.ts'

export function useAuthenticatedGuard(): NavigationGuard {
  return async (to) => {
    const { isAuthenticated } = useAuthenticationStore()

    if (isAuthenticated) {
      return true
    }

    sessionStorage[StorageKeys.loginRedirection] = JSON.stringify(to)
    return { name: RouteNames.LOGIN }
  }
}

export function useUnauthenticatedGuard(): NavigationGuard {
  return async () => {
    const { isAuthenticated } = useAuthenticationStore()

    if (isAuthenticated) {
      const { getGatewayOperator } = useUserStore()
      const gatewayOperator = await getGatewayOperator()

      return {
        name: RouteNames.GATEWAY_GROUPS__OVERVIEW,
        params: {
          gatewayOperatorId: gatewayOperator.value.id,
        },
      }
    }

    return true
  }
}

export function useDefaultRouteGuard(): NavigationGuard {
  const authenticatedGuard = useAuthenticatedGuard()
  const unauthenticatedGuard = useUnauthenticatedGuard()

  return async (to, from) => {
    const authenticatedResult = await authenticatedGuard(to, from, () => {})
    if (authenticatedResult !== true) {
      return authenticatedResult
    }

    return unauthenticatedGuard(to, from, () => {})
  }
}
