export type GlobalConfig = {
  SENTRY_ENVIRONMENT: 'dev' | 'stage' | 'prod'
  SENTRY_DSN: string
  SENTRY_RELEASE: string
  BACKEND_URL: string
  OPENAPI_PATH: string
}

declare global {
  interface Window {
    __ENV__: GlobalConfig
  }
}

export const config: GlobalConfig = {
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? window.__ENV__?.SENTRY_ENVIRONMENT,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? window.__ENV__?.SENTRY_DSN,
  SENTRY_RELEASE: import.meta.env.VITE_SENTRY_RELEASE ?? window.__ENV__.SENTRY_RELEASE,
  BACKEND_URL: import.meta.env.VITE_BACKEND_URL ?? window.__ENV__?.BACKEND_URL,
  OPENAPI_PATH: import.meta.env.VITE_OPENAPI_PATH ?? window.__ENV__?.OPENAPI_PATH,
}
