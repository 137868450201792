import { defineStore } from 'pinia'
import { shallowRef } from 'vue'
import axios from 'axios'
import { setUser as setSentryUser } from '@sentry/browser'
import { UserDisplayableError } from '@gcx-si/vue-components/UserDisplayableError'
import { useRouter } from 'vue-router'

import type { AxiosError } from 'axios'

import { useUserStore } from '@/stores/useUserStore.ts'
import { RouteNames } from '@/setup/constants.ts'

export const useAuthenticationStore = defineStore('authenticationStore', () => {
  const isAuthenticated = shallowRef(sessionStorage.username && sessionStorage.password)
  const { getGatewayOperator, resetGatewayOperator } = useUserStore()
  const { push } = useRouter()

  if (isAuthenticated.value) {
    axios.defaults.auth = { username: sessionStorage.username, password: sessionStorage.password }
  }

  async function login(username: string, password: string) {
    try {
      axios.defaults.auth = { username, password }
      isAuthenticated.value = true

      sessionStorage.username = username
      sessionStorage.password = password

      const operator = await getGatewayOperator()

      setSentryUser({
        id: operator.value.id,
        name: operator.value.name,
        username,
      })

      return true
    } catch (error) {
      cleanup()
      return false
    }
  }

  function cleanup() {
    axios.defaults.auth = undefined
    isAuthenticated.value = false
    setSentryUser(null)
    resetGatewayOperator()

    delete sessionStorage.username
    delete sessionStorage.password
  }

  async function logout() {
    cleanup()

    return push({
      name: RouteNames.LOGIN,
    })
  }

  axios.interceptors.response.use(
    undefined,
    async (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        await logout()

        return Promise.reject(new UserDisplayableError({
          summary: 'Logged out',
          detail: 'Please log in and try again',
          id: 'logged-out',
          severity: 'warn',
        }))
      }

      return Promise.reject(error)
    }
  )

  return { login, logout, isAuthenticated }
})
