import axios from 'axios'

import { config } from '@/setup/config.ts'

export function setupOpenApi() {
  axios.defaults.baseURL = config.BACKEND_URL

  axios.defaults.paramsSerializer = {
    ...axios.defaults.paramsSerializer,
    /**
       * Makes array params serialization use the `?param=value&param=other_value` format
       */
    indexes: null,
  }
}
