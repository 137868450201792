import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { RouteNames } from '@/setup/constants.ts'

export default {
  path: '/api-documentation',
  component: retriableLazyImport(() => import('./PageApiDocumentation.vue')),
  name: RouteNames.API_DOCUMENTATION,
} as RouteRecordRaw
